import {Component} from '@angular/core'
import {Router} from '@angular/router'
import {filter} from 'rxjs'
import {environment} from '../../../environments/environment'
import {ADMIN_HOME_PATH} from '../../application/types'
import {BaseComponent} from '../../base/base.component'
import {ConfigService} from '../../services/config.service'
import { BankIdComponent } from '@sparbanken-syd/sparbanken-syd-bankid'

@Component({
  selector: 'spb-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  imports: [BankIdComponent]
})
export class LoginComponent extends BaseComponent {
  public bankIdUrl = environment.authServiceUrl

  constructor(
    private router: Router,
    private configService: ConfigService
  ) {
    super()
  }

  public onAccessToken(accessToken: string | null) {
    this.subs.push(
      this.configService.setToken(accessToken)
        .pipe(filter(Boolean))
        .subscribe(() => {
          // Navigate home after a correct login
          this.router.navigate([ADMIN_HOME_PATH]).then()
        })
    )
  }
}
